
 document.addEventListener('DOMContentLoaded', function(event) 
 {   
     abilita = true;
     if (abilita == true) {

    draggable_element = document.getElementById("italimpresa-draggable");

    var theThing = document.querySelector("#italimpresa-draggable");
    var container = document.querySelector("#italimpresa-body");
     
    container.addEventListener("dblclick", getClickPosition, false);
     
    function getClickPosition(e) {
        var parentPosition = getPosition(e.currentTarget);
        var xPosition = e.clientX - parentPosition.x ;
        var yPosition = e.clientY - parentPosition.y ;
         
        theThing.style.left = xPosition + 120 + "px";
        theThing.style.top = yPosition + "px";
    }
     
    // Helper function to get an element's exact position
    function getPosition(el) {
      var xPos = 0;
      var yPos = 0;
     
      while (el) {
        if (el.tagName == "BODY") {
          // deal with browser quirks with body/window/document and page scroll
          var xScroll = el.scrollLeft || document.documentElement.scrollLeft;
          var yScroll = el.scrollTop || document.documentElement.scrollTop;
     
          xPos += (el.offsetLeft - xScroll + el.clientLeft);
          yPos += (el.offsetTop - yScroll + el.clientTop);
        } else {
          // for all other non-BODY elements
          xPos += (el.offsetLeft - el.scrollLeft + el.clientLeft);
          yPos += (el.offsetTop - el.scrollTop + el.clientTop);
        }
     
        el = el.offsetParent;
      }
      return {
        x: xPos,
        y: yPos
      };
    }

    // Rendi l'elemento DIV draggable
    dragElement(document.getElementById("italimpresa-draggable"));

    function dragElement(elmnt) 
    {
        var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;

        if (document.getElementById(elmnt.id + "header")) 
        {
            // Se l'header è presente, verrà utilizzato come "punto di presa"
            // per il trascinamento
            document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
        } 
        else 
        {
            // altrimenti, verrà utilizzato un qualsiasi punto nel div
            elmnt.onmousedown = dragMouseDown;
        }

        function dragMouseDown(e) 
        {
            e = e || window.event;
            e.preventDefault();
            // Render la posizione attuale del mouse "il punto di partenza"
            pos3 = e.clientX;
            pos4 = e.clientY;
            document.onmouseup = closeDragElement;
            // Ad ogni movimento del mouse viene utilizzata la funzione
            // elementDrag definita qua sotto
            document.onmousemove = elementDrag;
        }

        function elementDrag(e) 
        {
            e = e || window.event;
            e.preventDefault();
            // Calcolo la nuova posizione del cursore
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;
            // Sposto l'elemento nella nuova posizione
            elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
            elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
        }

        function closeDragElement() 
        {
            // Quando rilascio il mouse, smetteo di chiamare le funzioni di drag
            document.onmouseup = null;
            document.onmousemove = null;
        }
    }
}});


